/* nothing to see here */

.App {
  margin: 0 30px;
  padding: 0 30px; }
  .App .header {
    margin: 1rem 0 0.5rem 0;
    height: 40px;
    vertical-align: middle; }
    .App .header h3 {
      font-size: 20px; }
    .App .header div {
      font-size: 0.8rem;
      color: #666; }
      .App .header div .btn-link {
        font-size: 0.8rem; }
  .App .placeholder {
    display: block;
    position: absolute;
    top: 50%;
    vertical-align: middle;
    text-align: center;
    font-size: 150%;
    width: calc(100% - 20px); }
  .App .footer {
    width: 100%;
    height: 50px;
    line-height: 50px;
    font-size: 80%;
    color: #666;
    text-align: center; }
    .App .footer .btn-link {
      font-size: 95%; }

.footer-logo {
  width: 50%; }

.explorer {
  height: calc(100vh - (40px + 40px + 40px));
  font-size: 0.8rem; }
  .explorer .filter-toggle {
    position: absolute;
    opacity: 0.7;
    font-size: 1rem;
    float: left;
    cursor: pointer;
    padding: 10px; }
  .explorer #filters .grade-filter {
    display: inline-block;
    width: 100%; }
    .explorer #filters .grade-filter .slider {
      width: 250px; }
    .explorer #filters .grade-filter .rc-slider {
      margin: 5px 15px 30px 5px; }
  .explorer #visualizer {
    padding: 0;
    border-left: 1px solid #eee;
    border-radius: 10px 0 0 10px;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0 4px 24px 2px rgba(0, 0, 0, 0.1);
    position: relative;
    margin-left: -10px; }
    .explorer #visualizer .visualizer-footer {
      text-align: center;
      font-size: 80%;
      color: #666; }
  .explorer #details {
    border-left: 1px solid #eee;
    border-radius: 10px 0 0 10px;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0 4px 24px 2px rgba(0, 0, 0, 0.1);
    margin-left: -20px;
    padding: 10px;
    overflow-y: scroll;
    position: relative;
    background-color: white; }
    .explorer #details .target-group {
      margin-left: 1rem;
      margin-bottom: 1.5rem; }
    .explorer #details > div > div.target-group {
      margin-left: 0; }
      .explorer #details > div > div.target-group > .target-group-header {
        margin-top: 0.5rem; }
    .explorer #details .expander {
      margin: 0.8rem 0;
      padding: 0.1rem 0.2rem;
      cursor: pointer;
      border: 1px solid #d3d3d3;
      color: #3e3e3e;
      background-color: #eee; }
      .explorer #details .expander > span {
        margin-right: 0.2rem; }

@font-face {
  font-family: 'IcoMoon-Free';
  src: url(/static/media/IcoMoon-Free.72c742bc.ttf); }

.icon {
  font-family: 'IcoMoon-Free' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  letter-spacing: 0;
  -webkit-font-feature-settings: "liga";
  -ms-font-feature-settings: "liga" 1;
  -o-font-feature-settings: "liga";
  font-feature-settings: "liga", normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }
  .icon.icon-filter:before {
    content: '\E992'; }

.sunburst-arc {
	cursor: pointer;
}

.sunburst-arc:hover {
	opacity: 0.3;
}

